<template>
  <div class="navbar-distance">
    <AppBreadcrumb :items="breadcrumb" :border="true" />
    <!-- nav -->
    <div class="py-6 py-lg-8">
      <div class="container">
        <h2 class="mb-4 mb-lg-8 text-center content-title">{{ title }}</h2>
        <h5 class="fs-7 mb-2 fw-light text-primary" v-if="applyDate">
          {{ applyDate }}
        </h5>
        <p class="mb-3 fw-light" v-if="subTitle">{{ subTitle }}</p>
        <div v-html="content" class="articleDetail fw-light"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subTitle: String,
    applyDate: String,
    content: String,
    breadcrumb: Array,
  },
};
</script>
