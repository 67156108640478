<template>
  <ArticleContentTemplate
    :breadcrumb="breadcrumb"
    :title="details.title"
    :subTitle="`作者:${details.author}`"
    :applyDate="$utilsFilters.dateStr(details.createTime)"
    :content="details.content"
  />
</template>

<script>
import { apiGetUserArticle } from "@/api/article";
import ArticleContentTemplate from "@/components/ArticleContentTemplate.vue";

export default {
  components: {
    ArticleContentTemplate,
  },
  data() {
    return {
      breadcrumb: [
        {
          id: 1,
          title: "最新消息",
          path: "/news",
        },
        {
          id: 2,
          title: "新聞消息",
          path: "/news",
        },
      ],
      details: {},
    };
  },
  methods: {
    async getArticle(id) {
      this.$vLoading(true);
      try {
        const res = await apiGetUserArticle(id);
        const { code, data, message } = res.data;
        if (code === 200) {
          const { title, author, createTime, content } = data;
          this.details = { title, author, createTime, content };
        } else {
          this.$vHttpsNotice({ statusCode: code, title: message });
          this.backToList();
        }
      } catch (error) {
        this.backToList();
      } finally {
        this.$vLoading(false);
      }
    },
    backToList() {
      this.$router.push("/news");
    },
  },
  created() {
    const { id } = this.$route.params;
    if (id) {
      this.getArticle(id);
    } else {
      this.backToList();
    }
  },
};
</script>
